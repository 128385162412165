import { inject } from '@vue/composition-api';
import AmplifyAuth, { CognitoUser } from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';

import { useMasters } from '@/compositions/masters';
import { useUtil } from '@/compositions/util';

import { getUser } from '@/graphql/queries';
import { putUser, updateUserDeleted } from '@/graphql/mutations';

import { authStoreKey } from '@/stores/auth';

import { event as gtagEvent } from 'vue-gtag';

export const useAuth = () => {
  const store = inject(authStoreKey);
  const masters = useMasters();
  const util = useUtil();
  if (!store) {
    throw new Error(`${authStoreKey} is not provided`);
  }

  const state = store.state;

  const clear = (): void => {
    Amplify.configure({
      aws_appsync_authenticationType: 'API_KEY',
    });
    state.signedIn = false;
    state.id = null;
    state.email = null;
    state.verified = false;
    state.notice = null;
    state.admin = false;
    state.properties = undefined;
    state.providerStatus = null;
    state.privateProperties = null;

    masters.load();
  };

  const getAttributes = (user: CognitoUser) => {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  const setCurrentUser = async (response: CognitoUser) => {
    if (response == null) {
      clear();
      return;
    }
    state.signedIn = true;
    state.id = response.getUsername();

    await Promise.all([
      getAttributes(response).then((result) => {
        for (const v of result as { Name: string; Value: string }[]) {
          if (v['Name'] == 'email') {
            state.email = v['Value'];
          }
          if (v['Name'] == 'email_verified') {
            state.verified = v['Value'] == 'true';
          }
        }
      }),
      getUser(state.id)
        .then((result) => {
          if (!result) throw 'error';
          state.admin = result.admin ? result.admin : false;
          state.notice = result.notice ? result.notice : null;
          state.providerStatus = result.providerStatus;
          if (result.properties) {
            state.properties = result.properties;
          } else {
            state.properties = null;
          }
          if (result.privateProperties) {
            state.privateProperties = result.privateProperties;
          } else {
            state.privateProperties = null;
          }
        })
        .catch(() => {
          state.admin = false;
          state.properties = null;
          state.privateProperties = null;
          state.verified = false;
          state.notice = null;
          state.providerStatus = null;
          if (state.id != null) putUser(state.id);
        }),
    ]);
  };

  const loadAmplifyUser = async (type = 'loadonly' as 'loadonly' | 'session' | 'email') => {
    await AmplifyAuth.currentAuthenticatedUser()
      .then(async (response) => {
        Amplify.configure({
          aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        });
        masters.load();
        if (type == 'session') {
          gtagEvent('login', { method: 'session' });
        } else if (type == 'email') {
          gtagEvent('login', { method: 'email' });
        }
        await setCurrentUser(response);
        state.loaded = true;
      })
      .catch(() => {
        clear();
        state.loaded = true;
      });
  };

  const changeEmail = async (email: string) => {
    const user = await AmplifyAuth.currentAuthenticatedUser();
    const result = await AmplifyAuth.updateUserAttributes(user, { email: email });
    return result == 'SUCCESS';
  };

  const sendVerifyEmail = () => {
    AmplifyAuth.verifyCurrentUserAttribute('email');
  };

  const verifyEmail = async (code: string) => {
    return await AmplifyAuth.verifyCurrentUserAttributeSubmit('email', code).catch((err) => {
      throw err;
    });
  };

  const deleteAccount = async () => {
    const id = state.id;
    if (!id) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await updateUserDeleted(id, true).catch((error: any) => {
      if (error) {
        throw error;
      }
    });
    const user = await AmplifyAuth.currentAuthenticatedUser();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user.deleteUser((error: any) => {
      if (error) {
        throw error;
      }
    });
  };

  const waitLoading = async () => {
    while (!state.loaded) {
      await util.sleep(100);
    }
  };

  const signOut = async (): Promise<void> => {
    try {
      await AmplifyAuth.signOut();
      clear();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    state,
    loadAmplifyUser,
    waitLoading,
    clear,
    signOut,
    changeEmail,
    sendVerifyEmail,
    verifyEmail,
    deleteAccount,
  };
};
