import { inject } from '@vue/composition-api';
import { errorStoreKey } from '@/stores/error';

export const useError = () => {
  const store = inject(errorStoreKey);
  if (!store) {
    throw new Error(`${errorStoreKey} is not provided`);
  }

  const state = store.state;

  const cause = () => {
    state.enabled = true;
  };

  return {
    state,
    cause,
  };
};
