/* eslint-disable prettier/prettier */

export default {
  'Back to Sign In': 'サインインに戻る',
  'Change': '変更',
  'Change Password': 'パスワードを変更',
  'Verification code': '認証コード',
  'Enter code': 'コードを入力',
  'Confirmation Code': '認証コード',
  'Enter your code': 'コードを入力',
  'Confirm Sign up': 'メールアドレスの認証',
  'Lost your code?': 'コードがありませんか？',
  'Resend Code': 'コードを再送',
  'Confirm': '確認',
  'Confirm SMS Code': 'SMSコードを確認',
  'Confirm TOTP Code': 'TOTPコードを確認',
  'Create account': 'アカウントを作成',
  'Email Address *': 'Eメールアドレス *',
  'Enter your email address': 'Eメールアドレスを入力',
  'Forgot your password?': 'ログインできない場合はこちらをお試しください:',
  'Less than two MFA types available': '2つ未満のMFAタイプが利用可能です',
  'New password': '新しいパスワード',
  'Enter your new password': '新しいパスワードを入力',
  'No account?': 'アカウントをお持ちでない場合',
  'Username cannot contain whitespace': 'ユーザー名にはスペースを使用できません',
  'Password cannot start or end with whitespace': 'パスワードの最初と最後の文字にはスペースを使用することはできません',
  'Password *': 'パスワード *',
  'Enter your password': 'パスワードを入力',
  'Phone Number *': '電話番号 *',
  '(555) 555-1212': '(555) 555-1212',
  'qrcode': 'QRコード',
  'Reset password': 'パスワードをリセット',
  'Reset your password': 'パスワードをリセット',
  'Select MFA Type': 'MFAタイプを選択',
  'Verify': '認証',
  'Send Code': '認証コードを送信',
  'Submit': '送信',
  'TOTP needs to be configured': 'TOTPが設定されている必要があります',
  'Sign In': 'ログイン',
  'Sign in to your account': 'アカウントにログイン',
  'Sign in': ' ',
  'Sign in with Amazon': 'Amazonでログイン',
  'Sign in with Auth0': 'Auth0でログイン',
  'Sign in with AWS': 'AWSでログイン',
  'Sign in with Facebook': 'Facebookでログイン',
  'Sign in with Google': 'Googleでログイン',
  'Sign Out': 'ログアウト',
  'Email': 'Eメール',
  'Have an account?': ' ',
  'Create a new account': '予約用アカウントを作成',
  'Password': 'パスワード',
  'Create Account': 'アカウントを作成',
  'Username': 'ユーザー名',
  'Success! Your MFA Type is now:': '成功しました。MFAタイプ:',
  'Scan then enter verification code': 'スキャンして認証コードを入力してください',
  'Enter Security Code:': 'セキュリティコードを入力:',
  'AWSCognito': 'AWSCognito',
  'TOTP Setup has failed': 'TOTPセットアップに失敗しました',
  'Verify Security Token': 'セキュリティトークンを認証',
  'Setup TOTP successfully!': 'TOTPセットアップが成功しました',
  'Failed! Unable to configure MFA at this time': '失敗しました。現在MFA設定ができません。',
  'Username *': 'ユーザー名 *',
  'Enter your username': 'ユーザー名を入力',
  'Account recovery requires verified contact information': 'アカウントの復元には認証済みの連絡先情報が必要です',
  'Phone Number': '電話番号',
  'Address': '住所',
  'Enter your address': '住所を入力',
  'Nickname': 'ニックネーム',
  'Enter your nickname': 'ニックネームを入力',
  'Birthday': '生年月日',
  'Enter your birthday': '生年月日を入力',
  'Picture URL': '写真のURL',
  'Enter your picture URL': '写真のURLを入力',
  'Family Name': '名字',
  'Enter your family name': '苗字を入力',
  'Preferred Username': '表示名',
  'Enter your preferred username': '表示名を入力',
  'Gender': '性別',
  'Enter your gender': '性別を入力',
  'Profile URL': 'プロフィールURL',
  'Enter your profile URL': 'プロフィールURLを入力',
  'First Name': '名前',
  'Enter your first name': '名前を入力',
  'Time zone': 'タイムゾーン',
  'Enter your time zone': 'タイムゾーンを入力',
  'Locale': 'ロケール',
  'Enter your locale': 'ロケールを入力',
  'Updated At': '更新日時',
  'Enter the time the information was last updated': '情報が最後に更新された時刻を入力してください',
  'Middle Name': 'ミドルネーム',
  'Enter your middle name': 'ミドルネームを入力',
  'Website': 'ウェブサイト',
  'Enter your website': 'ウェブサイトを入力',
  'Full Name': 'フルネーム',
  'Enter your full name': 'フルネームを入力',
  'Picker Title': 'タイトル',
  'Ancillary text or content may occupy this space here': '補助的なテキストまたはコンテンツがこのスペースを占める可能性があります',
  'Placeholder hint': 'プレースホルダーヒント',
  'Button': 'ボタン',
  'Add Profile Photo': 'プロフィール写真を追加',
  'Preview the image before upload': 'アップロードする前に画像をプレビューする',
  'Tap to image select': 'タップして画像を選択',
  'Upload': 'アップロード',
  'Pick a file': 'ファイルを選択',
  'Fallback Content': 'フォールバックコンテンツ',
  'Confirm Sign Up Failed': 'サインアップの認証に失敗しました',
  'Sign Up Failed': 'サインアップに失敗しました',
  'Authentication Error': '認証エラー',
  'Username cannot be empty': 'ユーザー名を入力してください',
  'The username should either be a string or one of the sign in types': 'ユーザー名の形式が正しくありません',
  'Password cannot be empty': 'パスワードを入力してください',
  'Confirmation code cannot be empty': '認証コードを入力してください',
  'Error creating account': 'サインアップエラー',
  'No valid MFA method provided': 'MFAが提供されていません',
  'Invalid MFA type': 'MFAタイプが正しく有りません',
  'Challenge response cannot be empty': 'チャレンジレスポンスを入力してください',
  'Failed to get the session because the user is empty': 'ユーザーが空のためセッションを取得できませんでした',
  'The following fields must be completed: Password': 'パスワードを入力してください',
  'User does not exist.': 'ユーザーが存在しません',
  'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
  'User is not confirmed.': 'ユーザーは認証されていません',
  'User already exists': 'ユーザーは既に存在します',
  'Invalid verification code provided, please try again.': '指定された認証コードが無効です。もう一度お試しください',
  'Invalid password format': 'パスワードのフォーマットが不正です',
  'Invalid phone number format': '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
  'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
  'Password attempts exceeded': 'パスワード試行回数が超過しました',
  'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
  'Username/client id combination not found.': 'ユーザーが存在しません',
  'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です',
  'Password does not conform to policy: Password not long enough': 'パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)',
  'Password does not conform to policy: Password must have uppercase characters': 'パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)',
  'Password does not conform to policy: Password must have lowercase characters': 'パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)',
  'Password does not conform to policy: Password must have numeric characters': 'パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)',
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください',
  "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at \"password\" failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください'
}
