































































































































































































































































































































import { defineComponent, reactive, provide, getCurrentInstance } from '@vue/composition-api';
import { register } from 'register-service-worker';

import { Hub } from '@aws-amplify/core';
import { event as gtagEvent } from 'vue-gtag';

import { useAuth } from '@/compositions/auth';
import { useSnackbar } from '@/compositions/snackbar';
import { useLoading } from '@/compositions/loading';
import { useHidden } from '@/compositions/hidden';
import { useError } from '@/compositions/error';
import { useScreen } from '@/compositions/screen';

import authStore, { authStoreKey } from '@/stores/auth';
import snackbarStore, { snackbarStoreKey } from '@/stores/snackbar';
import loadingStore, { loadingStoreKey } from '@/stores/loading';
import hiddenStore, { hiddenStoreKey } from '@/stores/hidden';
import errorStore, { errorStoreKey } from '@/stores/error';
import mastersStore, { mastersStoreKey } from '@/stores/masters';

import goTo from 'vuetify/lib/services/goto';

const LogoBtn = () => import(/* webpackChunkName: "LogoBtn" */ '@/components/LogoBtn.vue');

import {
  mdiAccountCircle,
  mdiLogin,
  mdiAccountCog,
  mdiForum,
  mdiListStatus,
  mdiCalendarMonth,
  mdiCalendarClock,
  mdiYoga,
  mdiCogs,
  mdiFaceAgent,
  mdiInstagram,
  mdiYoutube,
  mdiTwitter,
  mdiClose,
  mdiReload,
  mdiChevronUp,
} from '@mdi/js';

export default defineComponent({
  components: {
    LogoBtn,
  },
  setup() {
    provide(authStoreKey, authStore());
    provide(snackbarStoreKey, snackbarStore());
    provide(loadingStoreKey, loadingStore());
    provide(hiddenStoreKey, hiddenStore());
    provide(errorStoreKey, errorStore());
    provide(mastersStoreKey, mastersStore());

    const instance = getCurrentInstance();
    if (!instance) throw new Error(`instance`);

    const state = reactive({
      visibleMenu: false,
      reloadbar: false,
      reloading: false,
      scrollFab: false,
      registration: null as ServiceWorkerRegistration | null,
    });
    const icons = {
      mdiAccountCircle,
      mdiLogin,
      mdiAccountCog,
      mdiForum,
      mdiListStatus,
      mdiCalendarMonth,
      mdiCalendarClock,
      mdiYoga,
      mdiCogs,
      mdiFaceAgent,
      mdiInstagram,
      mdiYoutube,
      mdiTwitter,
      mdiClose,
      mdiReload,
      mdiChevronUp,
    };

    const snackbar = useSnackbar();
    const loading = useLoading();
    const hidden = useHidden();
    const error = useError();
    const screen = useScreen();

    const auth = useAuth();
    Hub.listen('auth', async (data) => {
      if (data.payload.event == 'signIn') {
        auth.loadAmplifyUser('email');
      } else if (data.payload.event == 'signUp') {
        gtagEvent('sign_up', { method: 'email' });
      }
    });
    auth.loadAmplifyUser('session');

    const onScroll = (event: { target: HTMLInputElement }): void => {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || event.target.scrollTop || 0;
      state.scrollFab = top > 20;
    };

    const toTop = (): void => {
      goTo(0, { duration: 500 });
    };

    const reload = (): void => {
      if (!state.registration || !state.registration.waiting) return;
      state.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    };

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (state.reloading) return;
        state.reloading = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });

      if (process.env.NODE_ENV === 'production') {
        register(`${process.env.BASE_URL}service-worker.js`, {
          ready() {
            console.log(
              'App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB',
            );
          },
          registered() {
            console.log('Service worker has been registered.');
          },
          cached() {
            console.log('Content has been cached for offline use.');
          },
          updatefound() {
            console.log('New content is downloading.');
          },
          updated(registration) {
            console.log('New content is available; please refresh.');
            state.registration = registration;
            state.reloadbar = true;
          },
          offline() {
            console.log('No internet connection found. App is running in offline mode.');
          },
          error(error) {
            console.error('Error during service worker registration:', error);
          },
        });
      }
    }

    return {
      state,
      icons,
      auth,
      snackbar,
      loading,
      hidden,
      error,
      screen,
      toTop,
      onScroll,
      reload,
    };
  },
  metaInfo: () => ({
    titleTemplate: '%s - 麻布十番・東麻布のパーソナルジム | CoCoDakara Body Design',
  }),
});
