/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ja from 'vuetify/src/locale/ja';

import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { ja },
    current: 'ja',
  },
  theme: {
    dark: true,
    options: {
      minifyTheme,
      themeCache: {
        get: (key: any) => localStorage.getItem(key),
        set: (key: any, value: any) => localStorage.setItem(key, value),
      },
    },
    themes: {
      dark: {
        primary: '#ffbe00',
        secondary: '#97a2ad',
        accent: '#ff585d',
        info: '#41b6e6',
      },
    },
  },
});
