import { InjectionKey, reactive } from '@vue/composition-api';

const hiddenStore = () => {
  const state = reactive({
    enabled: false as boolean,
  });
  return {
    state,
  };
};

export default hiddenStore;
export type HiddenStore = ReturnType<typeof hiddenStore>;
export const hiddenStoreKey: InjectionKey<HiddenStore> = Symbol('hiddenStore');
