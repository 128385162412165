import { InjectionKey, reactive } from '@vue/composition-api';

const errorStore = () => {
  const state = reactive({
    enabled: false as boolean,
  });
  return {
    state,
  };
};

export default errorStore;
export type ErrorStore = ReturnType<typeof errorStore>;
export const errorStoreKey: InjectionKey<ErrorStore> = Symbol('errorStore');
