import API, { graphqlOperation } from '@aws-amplify/api';

import { v4 as uuid } from 'uuid';

import types from '@/types/appsync';

export const putAsset = async (file: File): Promise<types.PutAssetParams> => {
  const p = new Promise<types.PutAssetParams>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const mutation = `
        mutation($fileName: String!, $base64: String!) {
          putAsset(fileName: $fileName, base64: $base64)
        }`;
      try {
        const rawResponse = await API.graphql(
          graphqlOperation(mutation, {
            fileName: `${uuid()}${file.name.replaceAll(' ', '')}`,
            base64: reader.result,
          }),
        );
        const response = rawResponse as types.AppSyncPutAsset;
        if (response.data.putAsset == null) throw new Error('putAsset is null');
        resolve(JSON.parse(response.data.putAsset) as types.PutAssetParams);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
  return await p;
};

export const deleteAsset = async (fileName: string): Promise<types.DeleteAssetParams> => {
  const mutation = `
    mutation($fileName: String!) {
      deleteAsset(fileName: $fileName)
    }`;
  const rawResponse = await API.graphql(graphqlOperation(mutation, { fileName: fileName }));
  const response = rawResponse as types.AppSyncDeleteAsset;
  if (response.data.deleteAsset == null) throw new Error('deleteAsset is null');
  return JSON.parse(response.data.deleteAsset) as types.DeleteAssetParams;
};

export const invokePrerendering = async (limitSeconds: number) => {
  const mutation = `
    mutation($limitSeconds: Int!) {
      invokePrerendering(limitSeconds: $limitSeconds)
    }`;
  const rawResponse = await API.graphql(graphqlOperation(mutation, { limitSeconds: limitSeconds }));
  const response = rawResponse as types.AppSyncInvokePrerendering;
  if (response.data.invokePrerendering == null) throw new Error('invokePrerendering is null');
  return response.data.invokePrerendering;
};

export const putUser = async (id: string) => {
  const mutation = `
    mutation($id: String!) {
      putUser(id: $id) {
        id
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(mutation, { id: id }));
  const response = rawResponse as types.AppSyncPutUser;
  if (response.data.putUser == null) throw new Error('putUser is null');
  return response.data.putUser;
};

export const updateUserProperties = async (
  self: string,
  familyName: string,
  firstName: string,
  familyNameKana: string,
  firstNameKana: string,
  phone: string,
  age: types.AgeType,
  purpose: types.PurposeType,
) => {
  const mutation = `
    mutation($self: String!, $familyName: String, $firstName: String, $familyNameKana: String, $firstNameKana: String, $phone: String, $age: String, $purpose: String) {
      updateUserProperties(self: $self, familyName: $familyName, firstName: $firstName, familyNameKana: $familyNameKana, firstNameKana: $firstNameKana, phone: $phone, age: $age, purpose: $purpose) {
        id
        properties {
          familyName
          firstName
          familyNameKana
          firstNameKana
          phone
          age
          purpose
        }
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      self: self,
      familyName: familyName,
      firstName: firstName,
      familyNameKana: familyNameKana,
      firstNameKana: firstNameKana,
      phone: phone,
      age: age,
      purpose: purpose,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateUserProperties;
  if (response.data.updateUserProperties == null) throw new Error('updateUserProperties is null');
  return response.data.updateUserProperties;
};

export const updateUserAdmin = async (id: string, admin: boolean | null) => {
  const mutation = `
    mutation($id: String!, $admin: Boolean!) {
      updateUserAdmin(id: $id, admin: $admin) {
        id
        admin
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      admin: admin,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateUserAdmin;
  if (response.data.updateUserAdmin == null) throw new Error('updateUserAdmin is null');
  return response.data.updateUserAdmin;
};

export const updateUserDeleted = async (self: string, deleted: boolean | null) => {
  const mutation = `
    mutation($self: String!, $deleted: Boolean!) {
      updateUserDeleted(self: $self, deleted: $deleted) {
        id
        deleted
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      self: self,
      deleted: deleted,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateUserDeleted;
  if (response.data.updateUserDeleted == null) throw new Error('updateUserDeleted is null');
  return response.data.updateUserDeleted;
};

export const updateUserProviderStatus = async (id: string, providerStatus: types.ProviderStatus) => {
  const mutation = `
    mutation($id: String!, $providerStatus: String) {
      updateUserProviderStatus(id: $id, providerStatus: $providerStatus) {
        id
        providerStatus
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      providerStatus: providerStatus,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateUserProviderStatus;
  if (response.data.updateUserProviderStatus == null) throw new Error('updateUserProviderStatus is null');
  return response.data.updateUserProviderStatus;
};

export const updateProviderProperties = async (
  self: string,
  {
    published = null,
    priority = null,
    tags = null,
    image = null,
    instagram = null,
    youtube = null,
    specs = null,
    headerTitle = null,
    headerSubtitle = null,
    title = null,
    overview = null,
    description = null,
    annotation = null,
    businessDays = null,
    reservationNotice = null,
  }: {
    published?: boolean | null;
    priority?: number | null;
    tags?: string[] | null;
    image?: string | null;
    instagram?: string | null;
    youtube?: string | null;
    specs?: string[] | null;
    headerTitle?: string | null;
    headerSubtitle?: string | null;
    title?: string | null;
    overview?: string | null;
    description?: string | null;
    annotation?: string | null;
    businessDays?: types.BusinessDays | null;
    reservationNotice?: string | null;
  },
) => {
  const mutation = `
    mutation(
      $self: String!,
      $published: Boolean,
      $priority: Int,
      $tags: [String],
      $image: String,
      $instagram: String,
      $youtube: String,
      $specs: [String],
      $headerTitle: String,
      $headerSubtitle: String,
      $title: String,
      $overview: String,
      $description: String,
      $annotation: String,
      $businessDays: AWSJSON,
      $reservationNotice: String,
    ) {
      updateProviderProperties(
        self: $self,
        published: $published,
        priority: $priority,
        tags: $tags,
        image: $image,
        instagram: $instagram,
        youtube: $youtube,
        specs: $specs,
        headerTitle: $headerTitle,
        headerSubtitle: $headerSubtitle,
        title: $title,
        overview: $overview,
        description: $description,
        annotation: $annotation,
        businessDays: $businessDays,
        reservationNotice: $reservationNotice
      ) {
        id,
        providerProperties {
          published
          priority
          tags
          image
          instagram
          youtube
          specs
          headerTitle
          headerSubtitle
          title
          overview
          description
          annotation
          businessDays {
            mon { weekdayName, holiday, facility, start, end }
            tue { weekdayName, holiday, facility, start, end }
            wed { weekdayName, holiday, facility, start, end }
            thu { weekdayName, holiday, facility, start, end }
            fri { weekdayName, holiday, facility, start, end }
            sat { weekdayName, holiday, facility, start, end }
            sun { weekdayName, holiday, facility, start, end }
          }
          reservationNotice
        }
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      self: self,
      published: published,
      priority: priority,
      tags: tags,
      image: image,
      instagram: instagram,
      youtube: youtube,
      specs: specs,
      headerTitle: headerTitle,
      headerSubtitle: headerSubtitle,
      title: title,
      overview: overview,
      description: description,
      annotation: annotation,
      businessDays: businessDays ? JSON.stringify(businessDays) : null,
      reservationNotice: reservationNotice,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateProviderProperties;
  if (response.data.updateProviderProperties == null) throw new Error('updateProviderProperties is null');
  return response.data.updateProviderProperties;
};

export const putFacility = async (name: string) => {
  const mutation = `
    mutation($name: String!) {
      putFacility(name: $name) {
        id
        name
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(mutation, { name: name }));
  const response = rawResponse as types.AppSyncPutFacility;
  if (response.data.putFacility == null) throw new Error('putFacility is null');
  return response.data.putFacility;
};

export const updateFacilityName = async (id: string, name: string) => {
  const mutation = `
    mutation($id: String!, $name: String!) {
      updateFacilityName(id: $id, name: $name) {
        id
        name
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      name: name,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateFacilityName;
  if (response.data.updateFacilityName == null) throw new Error('updateFacilityName is null');
  return response.data.updateFacilityName;
};

export const deleteFacility = async (id: string) => {
  const mutation = `
    mutation($id: String!) {
      deleteFacility(id: $id) {
        id
        name
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
    }),
  );
  const response = rawResponse as types.AppSyncDeleteFacility;
  if (response.data.deleteFacility == null) throw new Error('deleteFacility is null');
  return response.data.deleteFacility;
};

export const putRoom = async (name: string, facility: string) => {
  const mutation = `
    mutation($name: String!, $facility: String!) {
      putRoom(name: $name, facility: $facility) {
        id
        name
        facility
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      name: name,
      facility: facility,
    }),
  );
  const response = rawResponse as types.AppSyncPutRoom;
  if (response.data.putRoom == null) throw new Error('putRoom is null');
  return response.data.putRoom;
};

export const updateRoomName = async (id: string, name: string) => {
  const mutation = `
    mutation($id: String!, $name: String!) {
      updateRoomName(id: $id, name: $name) {
        id
        name
        facility
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      name: name,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateRoomName;
  if (response.data.updateRoomName == null) throw new Error('updateRoomName is null');
  return response.data.updateRoomName;
};

export const deleteRoom = async (id: string) => {
  const mutation = `
    mutation($id: String!) {
      deleteRoom(id: $id) {
        id
        name
        facility
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
    }),
  );
  const response = rawResponse as types.AppSyncDeleteRoom;
  if (response.data.deleteRoom == null) throw new Error('deleteRoom is null');
  return response.data.deleteRoom;
};

export const putCourse = async (name: string) => {
  const mutation = `
    mutation($name: String!) {
      putCourse(name: $name) {
        id
        name
        length
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      name: name,
    }),
  );
  const response = rawResponse as types.AppSyncPutCourse;
  if (response.data.putCourse == null) throw new Error('putCourse is null');
  return response.data.putCourse;
};

export const updateCourseName = async (id: string, name: string) => {
  const mutation = `
    mutation($id: String!, $name: String!) {
      updateCourseName(id: $id, name: $name) {
        id
        name
        length
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      name: name,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateCourseName;
  if (response.data.updateCourseName == null) throw new Error('updateCourseName is null');
  return response.data.updateCourseName;
};

export const updateCourseLength = async (id: string, length: number) => {
  const mutation = `
    mutation($id: String!, $length: Int!) {
      updateCourseLength(id: $id, length: $length) {
        id
        name
        length
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      length: length,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateCourseLength;
  if (response.data.updateCourseLength == null) throw new Error('updateCourseLength is null');
  return response.data.updateCourseLength;
};

export const updateCoursePriority = async (id: string, priority: number) => {
  const mutation = `
    mutation($id: String!, $priority: Int!) {
      updateCoursePriority(id: $id, priority: $priority) {
        id
        priority
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      priority: priority,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateCoursePriority;
  if (response.data.updateCoursePriority == null) throw new Error('updateCoursePriority is null');
  return response.data.updateCoursePriority;
};

export const updateCourseTags = async (id: string, tags: string[]) => {
  const mutation = `
    mutation($id: String!, $tags: [String]!) {
      updateCourseTags(id: $id, tags: $tags) {
        id
        tags
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      tags: tags,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateCourseTags;
  if (response.data.updateCourseTags == null) throw new Error('updateCourseTags is null');
  return response.data.updateCourseTags;
};

export const updateCourseArchived = async (id: string, archived: boolean) => {
  const mutation = `
    mutation($id: String!, $archived: Boolean!) {
      updateCourseArchived(id: $id, archived: $archived) {
        id
        archived
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      archived: archived,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateCourseArchived;
  if (response.data.updateCourseArchived == null) throw new Error('updateCourseArchived is null');
  return response.data.updateCourseArchived;
};

export const deleteCourse = async (id: string) => {
  const mutation = `
    mutation($id: String!) {
      deleteCourse(id: $id) {
        id
        name
        length
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
    }),
  );
  const response = rawResponse as types.AppSyncDeleteCourse;
  if (response.data.deleteCourse == null) throw new Error('deleteCourse is null');
  return response.data.deleteCourse;
};

export const putTentativeReservation = async (
  user: string,
  provider: string,
  facility: string,
  course: string,
  timeFrom: number,
  timeTo: number,
) => {
  const mutation = `
    mutation($user: String!, $provider: String!, $facility: String!, $course: String!, $timeFrom: Int!, $timeTo: Int!) {
      putTentativeReservation(user: $user, provider: $provider, facility: $facility, course: $course, timeFrom: $timeFrom, timeTo: $timeTo) {
        id
        user
        provider
        facility
        course
        timeFrom
        timeTo
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      user: user,
      provider: provider,
      facility: facility,
      course: course,
      timeFrom: timeFrom,
      timeTo: timeTo,
    }),
  );
  const response = rawResponse as types.AppSyncPutTentativeReservation;
  if (response.data.putTentativeReservation == null) throw new Error('putTentativeReservation is null');
  return response.data.putTentativeReservation;
};

export const putEstablishedReservation = async (
  user: string,
  provider: string,
  facility: string,
  room: string,
  course: string,
  timeFrom: number,
  timeTo: number,
) => {
  const mutation = `
    mutation($user: String!, $provider: String!, $facility: String!, $room: String!, $course: String!, $timeFrom: Int!, $timeTo: Int!) {
      putEstablishedReservation(user: $user, provider: $provider, facility: $facility, room: $room, course: $course, timeFrom: $timeFrom, timeTo: $timeTo) {
        id
        user
        provider
        facility
        room
        course
        timeFrom
        timeTo
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      user: user,
      provider: provider,
      facility: facility,
      room: room,
      course: course,
      timeFrom: timeFrom,
      timeTo: timeTo,
    }),
  );
  const response = rawResponse as types.AppSyncPutEstablishedReservation;
  if (response.data.putEstablishedReservation == null) throw new Error('putEstablishedReservation is null');
  return response.data.putEstablishedReservation;
};

export const putReservationKeptByProvider = async (provider: string, timeFrom: number, timeTo: number) => {
  const mutation = `
    mutation($provider: String!, $timeFrom: Int!, $timeTo: Int!) {
      putReservationKeptByProvider(provider: $provider, timeFrom: $timeFrom, timeTo: $timeTo) {
        id
        type
        provider
        course
        timeFrom
        timeTo
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      provider: provider,
      timeFrom: timeFrom,
      timeTo: timeTo,
    }),
  );
  const response = rawResponse as types.AppSyncPutReservationKeptByProvider;
  if (response.data.putReservationKeptByProvider == null) throw new Error('putReservationKeptByProvider is null');
  return response.data.putReservationKeptByProvider;
};

export const updateReservationAsEstablished = async (id: string, provider: string, room: string) => {
  const mutation = `
    mutation($id: String!, $provider: String!, $room: String!) {
      updateReservationAsEstablished(id: $id, provider: $provider, room: $room) {
        id
        type
        timeFrom
        timeTo
        user
        provider
        facility
        room
        course
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      provider: provider,
      room: room,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateReservationAsEstablished;
  if (response.data.updateReservationAsEstablished == null) throw new Error('updateReservationAsEstablished is null');
  return response.data.updateReservationAsEstablished;
};

export const updateReservationAsCanceled = async (
  id: string,
  cancelReason: string,
  user: string | null,
  provider: string | null,
) => {
  const mutation = `
    mutation($id: String!, $cancelReason: String, $user: String, $provider: String) {
      updateReservationAsCanceled(id: $id, cancelReason: $cancelReason, user: $user, provider: $provider) {
        id
        type
        timeFrom
        timeTo
        user
        provider
        course
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      cancelReason: cancelReason,
      user: user,
      provider: provider,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateReservationAsCanceled;
  if (response.data.updateReservationAsCanceled == null) throw new Error('updateReservationAsCanceled is null');
  return response.data.updateReservationAsCanceled;
};

export const updateReservationProvider = async (id: string, provider: string) => {
  const mutation = `
    mutation($id: String!, $provider: String!) {
      updateReservationProvider(id: $id, provider: $provider) {
        id
        provider
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      provider: provider,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateReservationProvider;
  if (response.data.updateReservationProvider == null) throw new Error('updateReservationProvider is null');
  return response.data.updateReservationProvider;
};

export const updateReservationNote = async (id: string, note: string | null) => {
  const mutation = `
    mutation($id: String!, $note: String) {
      updateReservationNote(id: $id, note: $note) {
        id
        note
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      note: note,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateReservationNote;
  if (response.data.updateReservationNote == null) throw new Error('updateReservationNote is null');
  return response.data.updateReservationNote;
};

export const deleteReservationKeptByProvider = async (id: string, provider: string) => {
  const mutation = `
    mutation($id: String!, $provider: String!) {
      deleteReservationKeptByProvider(id: $id, provider: $provider) {
        id
        type
        provider
        course
        timeFrom
        timeTo
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      provider: provider,
    }),
  );
  const response = rawResponse as types.AppSyncDeleteReservationKeptByProvider;
  if (response.data.deleteReservationKeptByProvider == null) throw new Error('deleteReservationKeptByProvider is null');
  return response.data.deleteReservationKeptByProvider;
};

export const putMessage = async (self: string, to: string, message: string) => {
  const mutation = `
    mutation($self: String!, $to: String!, $message: String!) {
      putMessage(self: $self, to: $to, message: $message) {
        id
        from
        to
        message
        read
        createdAt
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      self: self,
      to: to,
      message: message.replace(/\n/g, '<br />'),
    }),
  );
  const response = rawResponse as types.AppSyncPutMessage;
  if (response.data.putMessage == null) throw new Error('putMessage is null');
  return response.data.putMessage;
};

export const putMessageFromNonuserToContact = async (message: string) => {
  const mutation = `
    mutation($message: String!) {
      putMessageFromNonuserToContact(message: $message) {
        id
        from
        to
        message
        createdAt
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      message: message.replace(/\n/g, '<br />'),
    }),
  );
  const response = rawResponse as types.AppSyncPutMessageFromNonuserToContact;
  if (response.data.putMessageFromNonuserToContact == null) throw new Error('putMessageFromNonuserToContact is null');
  return response.data.putMessageFromNonuserToContact;
};

export const updateMessageRead = async (id: string, self: string, read: boolean) => {
  const mutation = `
    mutation($id: String!, $self: String!, $read: Boolean!) {
      updateMessageRead(id: $id, self: $self, read: $read) {
        id
        from
        to
        message
        read
        createdAt
        readAt
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      self: self,
      read: read,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateMessageRead;
  if (response.data.updateMessageRead == null) throw new Error('updateMessageRead is null');
  return response.data.updateMessageRead;
};

export const putArticle = async () => {
  const mutation = `
    mutation {
      putArticle {
        id
        subject
        summary
        published
        timestamp
        createdAt
        updatedAt
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(mutation, {}));
  const response = rawResponse as types.AppSyncPutArticle;
  if (response.data.putArticle == null) throw new Error('putArticle is null');
  return response.data.putArticle;
};

export const updateArticle = async (
  id: string,
  subject: string,
  text: string,
  html: string,
  summary: string,
  timestamp: number,
) => {
  const mutation = `
    mutation($id: String!, $subject: String!, $text: String!, $html: String!, $summary: String!, $timestamp: Int!) {
      updateArticle(id: $id, subject: $subject, text: $text, html: $html, summary: $summary, timestamp: $timestamp) {
        id
        subject
        text
        summary
        published
        timestamp
        createdAt
        updatedAt
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      subject: subject,
      text: text,
      html: html,
      summary: summary,
      timestamp: timestamp,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateArticle;
  if (response.data.updateArticle == null) throw new Error('updateArticle is null');
  return response.data.updateArticle;
};

export const updateArticlePublished = async (id: string, published: boolean) => {
  const mutation = `
    mutation($id: String!, $published: Boolean!) {
      updateArticlePublished(id: $id, published: $published) {
        id
        subject
        html
        summary
        published
        timestamp
        createdAt
        updatedAt
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
      published: published,
    }),
  );
  const response = rawResponse as types.AppSyncUpdateArticlePublished;
  if (response.data.updateArticlePublished == null) throw new Error('updateArticlePublished is null');
  return response.data.updateArticlePublished;
};

export const deleteArticle = async (id: string) => {
  const mutation = `
    mutation($id: String!) {
      deleteArticle(id: $id) {
        id
        subject
        summary
        published
        timestamp
        createdAt
        updatedAt
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(mutation, {
      id: id,
    }),
  );
  const response = rawResponse as types.AppSyncDeleteArticle;
  if (response.data.deleteArticle == null) throw new Error('deleteArticle is null');
  return response.data.deleteArticle;
};
