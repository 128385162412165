import { InjectionKey, reactive, computed } from '@vue/composition-api';

import { Facility, Room, Provider, Course } from '@/types/appsync';

const mastersStore = () => {
  const state: {
    loaded: boolean;
    //
    facilities: Facility[];
    facilityById: { [id: string]: Facility };
    rooms: Room[];
    roomById: { [id: string]: Room };
    roomsByFacility: { [id: string]: Room[] };
    //
    courses: Course[];
    courseById: { [id: string]: Course };
    //
    providers: Provider[];
    providersSorted: Provider[];
    providersPublished: Provider[];
    providerById: { [id: string]: Provider };
    providerNameById: { [id: string]: string };
    providerNameKanaById: { [id: string]: string };
    //
    allUserLoaded: boolean;
    userNames: { [id: string]: string };
    userNameKanas: { [id: string]: string };
  } = reactive({
    loaded: false,
    facilities: [],
    facilityById: computed(() => {
      return Object.fromEntries(state.facilities.map((item) => [item.id, item]));
    }),
    rooms: [],
    roomById: computed(() => {
      return Object.fromEntries(state.rooms.map((item) => [item.id, item]));
    }),
    roomsByFacility: computed(() => {
      return state.rooms.reduce((item: { [id: string]: Room[] }, key) => {
        if (!Array.isArray(item[key.facility])) {
          item[key.facility] = [];
        }
        item[key.facility].push(key);
        return item;
      }, {});
    }),
    //
    courses: [],
    courseById: computed(() => {
      return Object.fromEntries(state.courses.map((item) => [item.id, item]));
    }),
    //
    providers: [],
    providersSorted: computed(() => {
      const ret = state.providers.filter((item) => item.providerProperties && !item.deleted);
      return ret.sort((a, b) => {
        const ap = a.providerProperties.priority;
        const bp = b.providerProperties.priority;
        if (ap === bp) return 0;
        if (ap === null) return 1;
        if (bp === null) return -1;
        return ap > bp ? 1 : -1;
      });
    }),
    providersPublished: computed(() => {
      return state.providersSorted.filter((item) => item.providerProperties.published);
    }),
    providerById: computed(() => {
      return Object.fromEntries(state.providers.map((item) => [item.id, item]));
    }),
    providerNameById: computed(() => {
      return Object.fromEntries(
        state.providers.map((item) => [
          item.id,
          item.properties ? `${item.properties.familyName} ${item.properties.firstName}` : '名称未設定',
        ]),
      );
    }),
    providerNameKanaById: computed(() => {
      return Object.fromEntries(
        state.providers.map((item) => [
          item.id,
          item.properties ? `${item.properties.familyNameKana} ${item.properties.firstNameKana}` : '',
        ]),
      );
    }),
    //
    allUserLoaded: false,
    userNames: {},
    userNameKanas: {},
  });
  return {
    state,
  };
};

export default mastersStore;
export type MastersStore = ReturnType<typeof mastersStore>;
export const mastersStoreKey: InjectionKey<MastersStore> = Symbol('mastersStore');
