import { inject } from '@vue/composition-api';
import { hiddenStoreKey } from '@/stores/hidden';

export const useHidden = () => {
  const store = inject(hiddenStoreKey);
  if (!store) {
    throw new Error(`${hiddenStoreKey} is not provided`);
  }

  const state = store.state;

  return {
    state,
  };
};
