import '@/mainComposition';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';

import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi);

import awsconfig from '@/config/env/production/amplify';
import awsconfigDev from '@/config/env/development/amplify';
import Amplify from '@aws-amplify/core';
if (process.env.NODE_ENV === 'production') {
  Amplify.configure(awsconfig);
} else {
  Amplify.configure(awsconfigDev);
}
Vue.config.ignoredElements = [/amplify-\w*/];

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
