import API, { graphqlOperation } from '@aws-amplify/api';
import types from '@/types/appsync';

export const getAssetList = async (): Promise<types.AssetInfo[]> => {
  const query = `
    query {
      getAssetList
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query));
  const al = (rawResponse as types.AppSyncGetAssetList).data.getAssetList;
  if (al == null) throw new Error('getAssetList is null');
  const assetList = JSON.parse(al) as types.AssetInfo[];
  return assetList;
};

export const getUserEmails = async (): Promise<types.UserEmails> => {
  const query = `
    query {
      getUserEmails
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query));
  const ue = (rawResponse as types.AppSyncGetUserEmails).data.getUserEmails;
  if (ue == null) throw new Error('getUserEmails is null');
  const userEmails = JSON.parse(ue).emails as types.UserEmails;
  return userEmails;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGcpApiResult = async (url: string): Promise<any> => {
  const query = `
    query($url: String!) {
      getGcpApiResult(url: $url)
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query, { url: url }));
  const response = (rawResponse as types.AppSyncGetGcpApiResult).data.getGcpApiResult;
  if (response == null) throw new Error('getGcpApiResult is null');
  return JSON.parse(response);
};

export const getUser = async (self: string): Promise<types.User> => {
  const query = `
    query($self: String!) {
      getUser(self: $self) {
        id
        admin
        deleted
        providerStatus
        notice
        properties {
          familyName
          firstName
          familyNameKana
          firstNameKana
          phone
          age
          purpose
        }
        privateProperties {
          icalSecret
        }
        createdAt
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query, { self: self }));
  const response = rawResponse as types.AppSyncGetUser;
  if (response.data.getUser == null) throw new Error('getUser is null');
  return response.data.getUser;
};

export const getUsers = async (): Promise<types.User[]> => {
  const query = `
  query($limit: Int, $nextToken: String) {
    getUsers(limit: $limit, nextToken: $nextToken) {
      items {
        id
        properties {
          familyName
          firstName
          familyNameKana
          firstNameKana
        }
        notice
        createdAt
      }
      nextToken
    }
  }`;
  const users = [] as types.User[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetUsers;
    if (response.data.getUsers == null) throw new Error('getUsers is null');
    nextToken = response.data.getUsers.nextToken;
    Array.prototype.push.apply(users, response.data.getUsers.items);
  } while (nextToken != null);
  return users;
};

export const getUsersByAdmin = async (admin: boolean): Promise<types.User[]> => {
  const query = `
    query($admin: Boolean!, $limit: Int, $nextToken: String) {
      getUsersByAdmin(admin: $admin, limit: $limit, nextToken: $nextToken) {
        items {
          id
          admin
          properties {
            familyName
            firstName
          }
        }
        nextToken
      }
    }`;
  const users = [] as types.User[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        admin: admin,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetUsersByAdmin;
    if (response.data.getUsersByAdmin == null) throw new Error('getUsersByAdmin is null');
    nextToken = response.data.getUsersByAdmin.nextToken;
    Array.prototype.push.apply(users, response.data.getUsersByAdmin.items);
  } while (nextToken != null);
  return users;
};

export const getUsersByProviderStatus = async (providerStatus: types.ProviderStatus): Promise<types.User[]> => {
  const query = `
    query($providerStatus: String!, $limit: Int, $nextToken: String) {
      getUsersByProviderStatus(providerStatus: $providerStatus, limit: $limit, nextToken: $nextToken) {
        items {
          id
          providerStatus
          properties {
            familyName
            firstName
          }
        }
        nextToken
      }
    }`;
  const users = [] as types.User[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        providerStatus: providerStatus,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetUsersByProviderStatus;
    if (response.data.getUsersByProviderStatus == null) throw new Error('getUsersByProviderStatus is null');
    nextToken = response.data.getUsersByProviderStatus.nextToken;
    Array.prototype.push.apply(users, response.data.getUsersByProviderStatus.items);
  } while (nextToken != null);
  return users;
};

export const getProviders = async (): Promise<types.Provider[]> => {
  const query = `
    query($limit: Int, $nextToken: String) {
      getProviders(limit: $limit, nextToken: $nextToken) {
        items {
          id
          properties {
            familyName
            firstName
            familyNameKana
            firstNameKana
          }
          deleted
          createdAt
          providerProperties {
            published
            priority
            tags
            image
            instagram
            youtube
            specs
            headerTitle
            headerSubtitle
            title
            overview
            description
            annotation
            businessDays {
              mon { weekdayName, holiday, facility, start, end }
              tue { weekdayName, holiday, facility, start, end }
              wed { weekdayName, holiday, facility, start, end }
              thu { weekdayName, holiday, facility, start, end }
              fri { weekdayName, holiday, facility, start, end }
              sat { weekdayName, holiday, facility, start, end }
              sun { weekdayName, holiday, facility, start, end }
            }
            reservationNotice
          }
        }
        nextToken
      }
    }`;
  const providers = [] as types.Provider[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetProviders;
    if (response.data.getProviders == null) throw new Error('getProviders is null');
    nextToken = response.data.getProviders.nextToken;
    Array.prototype.push.apply(providers, response.data.getProviders.items);
  } while (nextToken != null);
  return providers;
};

export const getReservations = async (
  searchTimeFrom: number,
  searchTimeTo: number,
  limit: number,
  nextToken: null | string,
): Promise<{ reservations: types.Reservation[]; nextToken: string | null }> => {
  const query = `
  query($searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
    getReservations(searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
      items {
        id
        provider
        type
        user
        facility
        room
        course
        timeFrom
        timeTo
        createdAt
        cancelReason
        cancelOperator
        canceledAt
      }
      nextToken
    }
  }`;
  const rawResponse = await API.graphql(
    graphqlOperation(query, {
      searchTimeFrom: searchTimeFrom,
      searchTimeTo: searchTimeTo,
      limit: limit,
      nextToken: nextToken,
    }),
  );
  const response = rawResponse as types.AppSyncGetReservations;
  if (response.data.getReservations == null) throw new Error('getReservations is null');
  return {
    reservations: response.data.getReservations.items.map((item) => {
      item.id = item.id.split('&')[0];
      return item;
    }),
    nextToken: response.data.getReservations.nextToken,
  };
};

export const getReservationsByProvider = async (
  provider: string,
  searchTimeFrom: number,
  searchTimeTo: number,
): Promise<types.Reservation[]> => {
  const query = `
    query($provider: String!, $searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
      getReservationsByProvider(provider: $provider, searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
        items {
          id
          provider
          type
          user
          facility
          room
          course
          timeFrom
          timeTo
          createdAt
          cancelReason
          cancelOperator
          canceledAt
        }
        nextToken
      }
    }`;

  const reservations = [] as types.Reservation[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        provider: provider,
        searchTimeFrom: searchTimeFrom,
        searchTimeTo: searchTimeTo,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetReservationsByProvider;
    if (response.data.getReservationsByProvider == null) throw new Error('getReservationsByProvider is null');
    nextToken = response.data.getReservationsByProvider.nextToken;
    Array.prototype.push.apply(reservations, response.data.getReservationsByProvider.items);
  } while (nextToken != null);
  return reservations;
};

export const getUserPrivateProperties = async (id: string): Promise<types.User> => {
  const query = `
    query($id: String!) {
      getUserPrivateProperties(id: $id) {
        id
        createdAt
        properties {
          familyName
          firstName
          familyNameKana
          firstNameKana
          phone
          age
          purpose
        }
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query, { id: id }));
  const response = rawResponse as types.AppSyncGetUserPrivateProperties;
  if (response.data.getUserPrivateProperties == null) throw new Error('getUserPrivateProperties is null');
  return response.data.getUserPrivateProperties;
};

export const getReservedUsers = async (self: string): Promise<types.ReservedUser[]> => {
  const query = `
    query($self: String!, $limit: Int, $nextToken: String) {
      getReservedUsers(self: $self, limit: $limit, nextToken: $nextToken) {
        items {
          user
        }
        nextToken
      }
    }`;
  const reservedUsers = [] as types.ReservedUser[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        self: self,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetReservedUsers;
    if (response.data.getReservedUsers == null) throw new Error('getReservedUsers is null');
    nextToken = response.data.getReservedUsers.nextToken;
    Array.prototype.push.apply(reservedUsers, response.data.getReservedUsers.items);
  } while (nextToken != null);
  return reservedUsers;
};

export const getUserPublicProperties = async (id: string): Promise<types.User> => {
  const query = `
    query($id: String!) {
      getUserPublicProperties(id: $id) {
        id
        deleted
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query, { id: id }));
  const response = rawResponse as types.AppSyncGetUserPublicProperties;
  if (response.data.getUserPublicProperties == null) throw new Error('getUserPublicProperties is null');
  return response.data.getUserPublicProperties;
};

export const getReservationsByUser = async (
  user: string,
  searchTimeFrom: number,
  searchTimeTo: number,
): Promise<types.Reservation[]> => {
  const query = `
    query($user: String!, $searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
      getReservationsByUser(user: $user, searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
        items {
          id
          provider
          type
          user
          facility
          room
          course
          timeFrom
          timeTo
          createdAt
          cancelReason
          cancelOperator
          canceledAt
        }
        nextToken
      }
    }`;

  const reservations = [] as types.Reservation[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        user: user,
        searchTimeFrom: searchTimeFrom,
        searchTimeTo: searchTimeTo,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetReservationsByUser;
    if (response.data.getReservationsByUser == null) throw new Error('getReservationsByUser is null');
    nextToken = response.data.getReservationsByUser.nextToken;
    Array.prototype.push.apply(reservations, response.data.getReservationsByUser.items);
  } while (nextToken != null);
  return reservations;
};

export const getReservationsByFacility = async (
  facility: string,
  searchTimeFrom: number,
  searchTimeTo: number,
): Promise<types.Reservation[]> => {
  const query = `
    query($facility: String!, $searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
      getReservationsByFacility(facility: $facility, searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
        items {
          id
          provider
          type
          user
          facility
          room
          course
          timeFrom
          timeTo
        }
        nextToken
      }
    }`;

  const reservations = [] as types.Reservation[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        facility: facility,
        searchTimeFrom: searchTimeFrom,
        searchTimeTo: searchTimeTo,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetReservationsByFacility;
    if (response.data.getReservationsByFacility == null) throw new Error('getReservationsByFacility is null');
    nextToken = response.data.getReservationsByFacility.nextToken;
    Array.prototype.push.apply(reservations, response.data.getReservationsByFacility.items);
  } while (nextToken != null);
  return reservations;
};

export const getReservationNote = async (id: string): Promise<types.ReservationNote> => {
  const query = `
    query($id: String!) {
      getReservationNote(id: $id) {
        id
        note
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query, { id: id }));
  const response = rawResponse as types.AppSyncGetReservationNote;
  if (response.data.getReservationNote == null) throw new Error('getReservationNote is null');
  return response.data.getReservationNote;
};

export const getFacilities = async (): Promise<types.Facility[]> => {
  const query = `
    query($limit: Int, $nextToken: String) {
      getFacilities(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
        }
        nextToken
      }
    }`;
  const facilities = [] as types.Facility[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetFacilities;
    if (response.data.getFacilities == null) throw new Error('getFacilities is null');
    nextToken = response.data.getFacilities.nextToken;
    Array.prototype.push.apply(facilities, response.data.getFacilities.items);
  } while (nextToken != null);
  return facilities;
};

export const getRooms = async (): Promise<types.Room[]> => {
  const query = `
    query($limit: Int, $nextToken: String) {
      getRooms(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          facility
        }
        nextToken
      }
    }`;
  const rooms = [] as types.Room[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetRooms;
    if (response.data.getRooms == null) throw new Error('getRooms is null');
    nextToken = response.data.getRooms.nextToken;
    Array.prototype.push.apply(rooms, response.data.getRooms.items);
  } while (nextToken != null);
  return rooms;
};

export const getRoomsByFacility = async (facility: string): Promise<types.Room[]> => {
  const query = `
    query($facility: String!, $limit: Int, $nextToken: String) {
      getRoomsByFacility(facility: $facility, limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          facility
        }
        nextToken
      }
    }`;
  const rooms = [] as types.Room[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        facility: facility,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetRoomsByFacility;
    if (response.data.getRoomsByFacility == null) throw new Error('getRoomsByFacility is null');
    nextToken = response.data.getRoomsByFacility.nextToken;
    Array.prototype.push.apply(rooms, response.data.getRoomsByFacility.items);
  } while (nextToken != null);
  return rooms;
};

export const getCourses = async (): Promise<types.Course[]> => {
  const query = `
    query($limit: Int, $nextToken: String) {
      getCourses(limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          length
          priority
          tags
          archived
        }
        nextToken
      }
    }`;
  const courses = [] as types.Course[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetCourses;
    if (response.data.getCourses == null) throw new Error('getCourses is null');
    nextToken = response.data.getCourses.nextToken;
    Array.prototype.push.apply(courses, response.data.getCourses.items);
  } while (nextToken != null);
  return courses;
};

export const getMessages = async (
  self: string,
  contact: string,
  searchTimeFrom: number,
  searchTimeTo: number,
  limit: number,
  nextToken: null | string,
): Promise<{ messages: types.Message[]; nextToken: string | null }> => {
  const query = `
    query($self: String!, $contact: String!, $searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
      getMessages(self: $self, contact: $contact, searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
        items {
          id
          from
          to
          message
          read
          createdAt
        }
        nextToken
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(query, {
      self: self,
      contact: contact,
      searchTimeFrom: searchTimeFrom,
      searchTimeTo: searchTimeTo,
      limit: limit,
      nextToken: nextToken,
    }),
  );
  const response = rawResponse as types.AppSyncGetMessages;
  if (response.data.getMessages == null) throw new Error('getMessages is null');
  return {
    messages: response.data.getMessages.items.map((item) => {
      item.id = item.id.split('&')[0];
      return item;
    }),
    nextToken: response.data.getMessages.nextToken,
  };
};

export const getMessagesUnread = async (
  self: string,
  searchTimeFrom: number,
  searchTimeTo: number,
): Promise<types.Message[]> => {
  const query = `
    query($self: String!, $searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
      getMessagesUnread(self: $self, searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
        items {
          id
          from
          createdAt
        }
        nextToken
      }
    }`;
  const messages = [] as types.Message[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        self: self,
        searchTimeFrom: searchTimeFrom,
        searchTimeTo: searchTimeTo,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetMessagesUnread;
    if (response.data.getMessagesUnread == null) throw new Error('getMessagesUnread is null');
    nextToken = response.data.getMessagesUnread.nextToken;
    Array.prototype.push.apply(messages, response.data.getMessagesUnread.items);
  } while (nextToken != null);
  return messages;
};

export const getMessageContacts = async (self: string): Promise<types.MessageContact[]> => {
  const query = `
    query($self: String!, $limit: Int, $nextToken: String) {
      getMessageContacts(self: $self, limit: $limit, nextToken: $nextToken) {
        items {
          contact
        }
        nextToken
      }
    }`;
  const messageContacts = [] as types.MessageContact[];
  let nextToken = null as null | string;
  do {
    const rawResponse = await API.graphql(
      graphqlOperation(query, {
        self: self,
        limit: 1000,
        nextToken: nextToken,
      }),
    );
    const response = rawResponse as types.AppSyncGetMessageContacts;
    if (response.data.getMessageContacts == null) throw new Error('getMessageContacts is null');
    nextToken = response.data.getMessageContacts.nextToken;
    Array.prototype.push.apply(messageContacts, response.data.getMessageContacts.items);
  } while (nextToken != null);
  return messageContacts;
};

export const getArticle = async (id: string): Promise<types.Article> => {
  const query = `
    query($id: String!) {
      getArticle(id: $id) {
        id
        subject
        text
        html
        summary
        published
        timestamp
        createdAt
        updatedAt
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query, { id: id }));
  const response = rawResponse as types.AppSyncGetArticle;
  if (response.data.getArticle == null) throw new Error('getArticle is null');
  return response.data.getArticle;
};

export const getPublishedArticle = async (id: string): Promise<types.Article> => {
  const query = `
    query($id: String!) {
      getPublishedArticle(id: $id) {
        id
        subject
        html
        summary
        published
        timestamp
        createdAt
        updatedAt
      }
    }`;
  const rawResponse = await API.graphql(graphqlOperation(query, { id: id }));
  const response = rawResponse as types.AppSyncGetPublishedArticle;
  if (response.data.getPublishedArticle == null) throw new Error('getPublishedArticle is null');
  return response.data.getPublishedArticle;
};

export const getArticles = async (
  searchTimeFrom: number,
  searchTimeTo: number,
  limit: number,
  nextToken: null | string,
): Promise<{ articles: types.Article[]; nextToken: string | null }> => {
  const query = `
    query($searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
      getArticles(searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
        items {
          id
          subject
          summary
          published
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(query, {
      searchTimeFrom: searchTimeFrom,
      searchTimeTo: searchTimeTo,
      limit: limit,
      nextToken: nextToken,
    }),
  );
  const response = rawResponse as types.AppSyncGetArticles;
  if (response.data.getArticles == null) throw new Error('getArticles is null');
  return {
    articles: response.data.getArticles.items.map((item) => {
      item.id = item.id.split('&')[0];
      return item;
    }),
    nextToken: response.data.getArticles.nextToken,
  };
};

export const getPublishedArticles = async (
  searchTimeFrom: number,
  searchTimeTo: number,
  limit: number,
  nextToken: null | string,
): Promise<{ articles: types.Article[]; nextToken: string | null }> => {
  const query = `
    query($searchTimeFrom: Int!, $searchTimeTo: Int!, $limit: Int, $nextToken: String) {
      getPublishedArticles(searchTimeFrom: $searchTimeFrom, searchTimeTo: $searchTimeTo, limit: $limit, nextToken: $nextToken) {
        items {
          id
          subject
          summary
          published
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
    }`;
  const rawResponse = await API.graphql(
    graphqlOperation(query, {
      searchTimeFrom: searchTimeFrom,
      searchTimeTo: searchTimeTo,
      limit: limit,
      nextToken: nextToken,
    }),
  );
  const response = rawResponse as types.AppSyncGetPublishedArticles;
  if (response.data.getPublishedArticles == null) throw new Error('getPublishedArticles is null');
  return {
    articles: response.data.getPublishedArticles.items.map((item) => {
      item.id = item.id.split('&')[0];
      return item;
    }),
    nextToken: response.data.getPublishedArticles.nextToken,
  };
};
