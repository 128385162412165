import moment from 'moment';
import 'moment/locale/ja';
moment.locale('ja');

import { inject, getCurrentInstance } from '@vue/composition-api';

import { mastersStoreKey } from '@/stores/masters';
import {
  getFacilities,
  getRooms,
  getUsers,
  getCourses,
  getProviders,
  getUserPrivateProperties as getUserPrivatePropertiesQuery,
} from '@/graphql/queries';

import { useError } from '@/compositions/error';
import { useUtil } from '@/compositions/util';

export const useMasters = () => {
  const store = inject(mastersStoreKey);
  if (!store) {
    throw new Error(`${mastersStoreKey} is not provided`);
  }
  const state = store.state;
  const error = useError();
  const util = useUtil();
  const instance = getCurrentInstance();
  if (!instance) throw new Error(`instance`);

  const load = async () => {
    await Promise.all([
      getFacilities()
        .then((result) => {
          state.facilities = result;
        })
        .catch(() => {
          state.facilities = [];
          console.log('店舗一覧の取得に失敗');
          error.cause();
        }),
      getRooms()
        .then((result) => {
          state.rooms = result;
        })
        .catch(() => {
          state.rooms = [];
          console.log('部屋一覧の取得に失敗');
          error.cause();
        }),
      getCourses()
        .then((result) => {
          state.courses = result.map((item) => {
            if (item.priority == null) {
              item.priority = 100;
            }
            return item;
          });
        })
        .catch(() => {
          state.courses = [];
          console.log('コース一覧の取得に失敗');
          error.cause();
        }),
      getProviders()
        .then((result) => {
          state.providers = result;
        })
        .catch(() => {
          console.log('トレーナー一覧の取得に失敗');
          error.cause();
        }),
    ]);
    state.loaded = true;
  };

  const waitLoading = async () => {
    while (!state.loaded) {
      await util.sleep(10);
    }
  };

  // Provider / Admin専用
  const loadAllUserNames = async () => {
    if (state.allUserLoaded) return;
    try {
      const users = await getUsers();
      const userNamePromises = users.map(async (user) => {
        const name = `${user.properties.familyName} ${user.properties.firstName}`;
        const nameKana = `${user.properties.familyNameKana} ${user.properties.firstNameKana}`;
        instance.proxy.$set(state.userNames, user.id, name);
        instance.proxy.$set(state.userNameKanas, user.id, nameKana);
        return name;
      });
      await Promise.all(userNamePromises);
      state.allUserLoaded = true;
    } catch (error) {
      console.log('ユーザー一覧の取得に失敗');
    }
  };

  const getUserName = async (id: string): Promise<string | null> => {
    if (state.userNames[id]) return state.userNames[id];
    if (id == 'system') return util.consts.systemDisplayName;
    if (id == 'contact') return util.consts.contactDisplayName;
    if (id == 'nonuser') return util.consts.nonuserDisplayName;
    if (id == 'block') return util.consts.blockDisplayName;
    if (id == 'rental') return util.consts.rentalDisplayName;
    if (id == 'other') return util.consts.otherDisplayName;
    let name = null;
    await getUserPrivatePropertiesQuery(id)
      .then((result) => {
        name = `${result.properties.familyName} ${result.properties.firstName}`;
        const nameKana = `${result.properties.familyNameKana} ${result.properties.firstNameKana}`;
        instance.proxy.$set(state.userNames, id, name);
        instance.proxy.$set(state.userNameKanas, id, nameKana);
      })
      .catch(() => {
        console.log('ユーザー名の取得に失敗');
        return null;
      });
    return name;
  };

  // 知っているユーザー名かトレーナー名を返す
  const getUserNameIKnow = (id: string): string => {
    if (id == 'system') return util.consts.systemDisplayName;
    if (id == 'contact') return util.consts.contactDisplayName;
    if (id == 'nonuser') return util.consts.nonuserDisplayName;
    if (id == 'block') return util.consts.blockDisplayName;
    if (id == 'rental') return util.consts.rentalDisplayName;
    if (id == 'other') return util.consts.otherDisplayName;
    if (id in state.providerNameById) return state.providerNameById[id];
    if (id in state.userNames) return state.userNames[id];
    return id;
  };

  const getUserNameKanaIKnow = (id: string): string => {
    if (id == 'system') return '';
    if (id == 'contact') return '';
    if (id == 'nonuser') return '';
    if (id == 'block') return '';
    if (id == 'rental') return '';
    if (id == 'other') return '';
    if (id in state.providerNameById) return state.providerNameKanaById[id];
    if (id in state.userNames) return state.userNameKanas[id];
    return id;
  };

  const getFacilityRoomName = (facilityId: string | null, roomId: string | null): string | null => {
    let fn = null as null | string;
    let rn = null as null | string;
    if (facilityId) {
      fn = state.facilityById[facilityId].name;
    }
    if (roomId) {
      rn = state.roomById[roomId].name;
    }
    if (fn && rn) return `${fn}(${rn})`;
    if (fn && !rn) return `${fn}`;
    return null;
  };

  const getCourseName = (courseId: string | null): string => {
    if (!courseId) {
      return '名称取得失敗';
    }
    if (!state.courseById[courseId]) {
      return '名称取得失敗';
    }
    return state.courseById[courseId].name;
  };

  return {
    state,
    load,
    waitLoading,
    loadAllUserNames,
    getUserName,
    getUserNameIKnow,
    getUserNameKanaIKnow,
    getFacilityRoomName,
    getCourseName,
  };
};
