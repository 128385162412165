const awsmobile = {
  aws_appsync_graphqlEndpoint: 'https://3o6a6eycj5dq3kujcttyskm4dy.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-northeast-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-g2bn6twljzggbcv6kcupo5zj64',
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_NZxJHAVTJ',
    userPoolWebClientId: '31rd925388u7csths9hnfki8ub',
  },
};

export default awsmobile;
