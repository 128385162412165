import { inject } from '@vue/composition-api';
import { loadingStoreKey } from '@/stores/loading';

export const useLoading = () => {
  const store = inject(loadingStoreKey);
  if (!store) {
    throw new Error(`${loadingStoreKey} is not provided`);
  }

  const state = store.state;

  return {
    state,
  };
};
