import { BusinessDays } from '@/types/appsync';

import configProd from '@/config/env/production/config';
import configDev from '@/config/env/development/config';
let config = configDev;
if (process.env.NODE_ENV === 'production') {
  config = configProd;
}

export const consts = {
  config: config,
  unixtimeMax: 2147483647,
  MaxReservationsByUser: 8,
  daysInAdvanceCanBeReserved: 182,
  minutesTentativeReservationDeadline: 120,
  minutesReservationEstablishingDeadline: 60,
  preparationMinutes: 15,
  systemDisplayName: '[CoCoDakara システム]',
  contactDisplayName: '[CoCoDakara お問い合わせ担当]',
  nonuserDisplayName: '[新規]',
  blockDisplayName: '[店舗ブロック]',
  rentalDisplayName: '[レンタル]',
  otherDisplayName: '[その他]',
  minutesReservationSearchBuffer: 120, // getReservationBy** のFromをこの時間だけ前から取得して、取り漏らさないようにする
  proxyCourseTag: '代理予約',
  firstReserveTag: '体験',
  trainingTag: 'トレーニング',
  stretchTag: 'ストレッチ',
  adminReservationTag: '管理',
};

export const setTitle = (title?: string | null, description?: string | null, addTitleToDescription = true) => {
  const site = '麻布十番・東麻布のパーソナルジム | CoCoDakara Body Design';
  let newTitle = '';
  let newOgType = '';
  if (title) {
    newTitle = title + ' - ' + site;
    newOgType = 'article';
  } else {
    newTitle = site;
    newOgType = 'website';
  }
  document.title = newTitle;

  const elem1 = document.querySelector("meta[id='ogtitle']");
  if (elem1) elem1.setAttribute('content', newTitle);

  const elem2 = document.querySelector("meta[id='ogtype']");
  if (elem2) elem2.setAttribute('content', newOgType);

  const elem3 = document.querySelector("meta[id='ogurl']");
  if (elem3) elem3.setAttribute('content', document.documentURI);

  const defaultDescription =
    '麻布十番・東麻布のストレッチ整体＆パーソナルトレーニングジム。妊婦から高齢の方まで通える、身体の不調も改善する総合トレーニングプログラムを提供中。';
  let newDesc = '';
  if (description) {
    newDesc = description;
  } else {
    newDesc = defaultDescription;
  }
  if (addTitleToDescription && title) {
    newDesc = title + ' - ' + newDesc;
  }

  const elem4 = document.querySelector("meta[id='desc']");
  if (elem4) elem4.setAttribute('content', newDesc);
  const elem5 = document.querySelector("meta[id='ogdesc']");
  if (elem5) elem5.setAttribute('content', newDesc);
};

export const setMeta = (
  noindex = false,
  richResultsFaq = false,
  articleLdJson: null | { datePublished: string; dateModified: string; headline: string; images: string[] } = null,
  scalable = false,
  home = false,
) => {
  const elemHead = document.querySelector('head');
  const elemTitle = document.querySelector('title');

  if (noindex) {
    if (!document.querySelector("meta[id='robots']")) {
      const meta = document.createElement('meta');
      meta.setAttribute('id', 'robots');
      meta.setAttribute('name', 'robots');
      meta.setAttribute('content', 'noindex');
      if (elemHead) elemHead.appendChild(meta);
    }
  } else {
    const meta = document.querySelector("meta[id='robots']");
    if (meta) {
      meta.remove();
    }
  }

  if (richResultsFaq) {
    if (!document.querySelector("script[id='rich-results-faq']")) {
      const script = document.createElement('script');
      script.setAttribute('id', 'rich-results-faq');
      script.setAttribute('type', 'application/ld+json');
      script.textContent = `{
  "@context": "http://schema.org/",
  "@type": "FAQPage",
  "description": "よくあるご質問",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "CoCoDakara Body Design 麻布十番店はどんなパーソナルジムですか？",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "全てのトレーナーが難関資格を保有しており、自分を高めることに妥協しないお客様へ、理論に基づいた高品質の体作りをサポートします。プロアスリート選手、歌手、モデルのトレーニングを手がける最高水準のメソッドをご体感いただけます。またパーソナルトレーニングをいつでも気軽に通っていただけるものにしたく、月会費不要の都度払いプランをご用意しています。"
      }
    },
    {
      "@type": "Question",
      "name": "店舗まではどうアクセスすればよいですか？",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "パーソナルジム CoCoDakara Body Design 麻布十番店は、東京メトロ南北線/都営地下鉄大江戸線 麻布十番駅から徒歩約4分、都営地下鉄大江戸線 赤羽橋駅から徒歩約4分の場所にございます。"
      }
    },
    {
      "@type": "Question",
      "name": "利用料金はいくらですか？",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "パーソナルジム CoCoDakara Body Design 麻布十番店は月会費無料の都度払いプランをご用意しており、1回あたり30分7,000円(税込7,700円)～ご利用いただけます。またコンディショニングを行うストレッチ整体コースも同額にてご用意しております。詳しくは料金ページ、トレーニングプランページをご確認ください。"
      }
    }
  ]
}`;
      if (elemHead && elemTitle) elemHead.insertBefore(script, elemTitle.nextSibling);
    }
  } else {
    const script = document.querySelector("script[id='rich-results-faq']");
    if (script) {
      script.remove();
    }
  }

  const viewportMeta = document.querySelector("meta[id='viewport']");
  if (viewportMeta) {
    if (scalable) {
      viewportMeta.setAttribute('content', 'width=device-width,initial-scale=1.0,minimum-scale=1.0');
    } else {
      viewportMeta.setAttribute('content', 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no');
    }
  }

  // ld+jsonの処理
  const oldLdJsonScript = document.querySelector("script[id='ld-json']");
  if (oldLdJsonScript) {
    oldLdJsonScript.remove();
  }

  const ldJsonScript = document.createElement('script');
  ldJsonScript.setAttribute('id', 'ld-json');
  ldJsonScript.setAttribute('type', 'application/ld+json');

  if (articleLdJson) {
    let image = ['https://bodydesign.cocodakara.net/img/main.webp'];
    if (articleLdJson.images.length > 0) {
      image = articleLdJson.images;
    }
    ldJsonScript.textContent = JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'Article',
      image: image,
      datePublished: articleLdJson.datePublished,
      dateModified: articleLdJson.dateModified,
      headline: articleLdJson.headline,
      author: [
        {
          '@type': 'Organization',
          name: 'CoCoDakara Body Design',
          url: 'https://bodydesign.cocodakara.net',
        },
      ],
      name: 'CoCoDakara Body Design 麻布十番店',
      alternateName: 'ココダカラ ボディデザイン',
      description:
        '麻布十番・東麻布のストレッチ整体＆パーソナルトレーニングジム。妊婦から高齢の方まで通える、身体の不調も改善する総合トレーニングプログラムを提供中。',
      sameAs: ['https://www.instagram.com/cocodakara_bodydesign/'],
    });
  } else {
    ldJsonScript.textContent = JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'ExerciseGym',
      url: 'https://bodydesign.cocodakara.net/',
      image: 'https://bodydesign.cocodakara.net/img/main.webp',
      name: 'CoCoDakara Body Design 麻布十番店',
      alternateName: 'ココダカラ ボディデザイン',
      description:
        '麻布十番・東麻布のストレッチ整体＆パーソナルトレーニングジム。妊婦から高齢の方まで通える、身体の不調も改善する総合トレーニングプログラムを提供中。',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '東麻布２丁目２２－５ ベルス麻布３階',
        addressLocality: '港区',
        addressRegion: '東京都',
        postalCode: '1060044',
        addressCountry: 'JP',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '35.6565414',
        longitude: '139.7406083',
      },
      telephone: '+81-70-8987-6824',
      priceRange: '¥7,700〜¥12,320',
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          opens: '9:00',
          closes: '22:00',
        },
      ],
      sameAs: ['https://www.instagram.com/cocodakara_bodydesign/'],
    });
  }

  if (elemHead && elemTitle) elemHead.insertBefore(ldJsonScript, elemTitle.nextSibling);

  // アフィリエイトタグを削除(必要な場合setMetaの後続で追加)
  const oldFelmatCvScript = document.querySelector("script[id='felmat-cv']");
  if (oldFelmatCvScript) {
    oldFelmatCvScript.remove();
  }

  // アフィリエイトのLPタグ(Homeのみにいれる)
  const oldFelmatLPScript = document.querySelector("script[id='felmat-lp']");
  if (oldFelmatLPScript) {
    oldFelmatLPScript.remove();
  }
  if (home) {
    const script = document.createElement('script');
    script.id = 'felmat-lp';
    script.type = 'text/javascript';
    script.src = 'https://js.crossees.com/csslp.js';
    script.async = true;
    document.head.appendChild(script);
  }
};

export const getDefaultBusinessDays = () => {
  return {
    mon: { weekdayName: '月', holiday: false, facility: config.mainFacility, start: 600, end: 1020 },
    tue: { weekdayName: '火', holiday: false, facility: config.mainFacility, start: 600, end: 1020 },
    wed: { weekdayName: '水', holiday: false, facility: config.mainFacility, start: 600, end: 1020 },
    thu: { weekdayName: '木', holiday: false, facility: config.mainFacility, start: 600, end: 1020 },
    fri: { weekdayName: '金', holiday: false, facility: config.mainFacility, start: 600, end: 1020 },
    sat: { weekdayName: '土', holiday: false, facility: config.mainFacility, start: 600, end: 1020 },
    sun: { weekdayName: '日', holiday: false, facility: config.mainFacility, start: 600, end: 1020 },
  };
};

export const loadAsset = (path: string) => {
  return require('@/assets/' + path);
};

export const useUtil = () => {
  const getBusinessDaysKey = (isoWeekday: number): keyof BusinessDays => {
    type t = keyof BusinessDays;
    switch (isoWeekday) {
      case 1:
        return 'mon' as t;
      case 2:
        return 'tue' as t;
      case 3:
        return 'wed' as t;
      case 4:
        return 'thu' as t;
      case 5:
        return 'fri' as t;
      case 6:
        return 'sat' as t;
      case 7:
        return 'sun' as t;
      default:
        return 'sun' as t;
    }
  };

  const businessDaysKeyToStr = (businessDayKey: keyof BusinessDays): string => {
    switch (businessDayKey) {
      case 'mon':
        return '月';
      case 'tue':
        return '火';
      case 'wed':
        return '水';
      case 'thu':
        return '木';
      case 'fri':
        return '金';
      case 'sat':
        return '土';
      case 'sun':
        return '日';
      default:
        return '日';
    }
  };

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  return {
    consts,
    setTitle,
    setMeta,
    getDefaultBusinessDays,
    getBusinessDaysKey,
    businessDaysKeyToStr,
    sleep,
    loadAsset,
  };
};
