import { inject } from '@vue/composition-api';
import { snackbarStoreKey } from '@/stores/snackbar';

export const useSnackbar = () => {
  const store = inject(snackbarStoreKey);
  if (!store) {
    throw new Error(`${snackbarStoreKey} is not provided`);
  }

  const state = store.state;

  const show = (text: string) => {
    state.text = text;
    state.visible = true;
  };

  return {
    state,
    show,
  };
};
