import { InjectionKey, reactive } from '@vue/composition-api';

const snackbarStore = () => {
  const state = reactive({
    visible: false as boolean,
    text: '' as string,
  });
  return {
    state,
  };
};

export default snackbarStore;
export type SnackbarStore = ReturnType<typeof snackbarStore>;
export const snackbarStoreKey: InjectionKey<SnackbarStore> = Symbol('snackbarStore');
