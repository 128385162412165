import { InjectionKey, reactive, computed } from '@vue/composition-api';
import { UserProperties, ProviderStatus, PrivateProperties } from '@/types/appsync';

const authStore = () => {
  const state: {
    loaded: boolean;
    signedIn: boolean;
    id: string | null;
    email: string | null;
    verified: boolean;
    notice: string | null;
    admin: boolean;
    providerStatus: ProviderStatus | null;
    properties: UserProperties | null | undefined;
    privateProperties: PrivateProperties | null;
    fullName: string;
  } = reactive({
    loaded: false,
    signedIn: false,
    id: null,
    email: null,
    verified: false,
    notice: null,
    admin: false,
    providerStatus: null,
    properties: undefined,
    privateProperties: null,
    fullName: computed(() => {
      if (!state.signedIn || !state.properties) return '名称未設定';
      return state.properties.familyName + state.properties.firstName;
    }),
  });
  return {
    state,
  };
};

export default authStore;
export type AuthStore = ReturnType<typeof authStore>;
export const authStoreKey: InjectionKey<AuthStore> = Symbol('authStore');
