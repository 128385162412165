import { reactive, onMounted, onBeforeUnmount } from '@vue/composition-api';

export const useScreen = () => {
  const state = reactive({
    isAboveSM: false as boolean,
    isAboveMD: false as boolean,
    isAboveLG: false as boolean,
  });

  const checkScreenSize = () => {
    state.isAboveSM = window.innerWidth >= 600;
    state.isAboveMD = window.innerWidth >= 960;
    state.isAboveLG = window.innerWidth >= 1280;
  };

  onMounted(() => {
    window.addEventListener('resize', checkScreenSize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkScreenSize);
  });

  checkScreenSize();

  return {
    state,
  };
};
