import { InjectionKey, reactive } from '@vue/composition-api';

const loadingStore = () => {
  const state = reactive({
    enabled: false as boolean,
  });
  return {
    state,
  };
};

export default loadingStore;
export type LoadingStore = ReturnType<typeof loadingStore>;
export const loadingStoreKey: InjectionKey<LoadingStore> = Symbol('loadingStore');
